export function scrollToElement(id) {
    const el = document.querySelector(id)
    if (el) {
        let offsetTop = el.offsetTop
        if (window.innerWidth < 1280 && window.innerWidth >= 768)
            offsetTop -= 120
        else if (window.innerWidth >= 1280) offsetTop -= 78

        window.scrollTo({
            top: offsetTop,
            left: 0,
            behavior: 'smooth',
        })
    }
}
export function scrollInToView(id) {
    const el = document.querySelector(id)

    el.scrollIntoView({
        behavior: 'smooth',
    })
}
export function addBackgroundImage(url, mbUrl) {
    if (mbUrl && window.innerWidth < 768)
        return { 'background-image': 'url(' + mbUrl + ')' }
    else return { 'background-image': 'url(' + url + ')' }
}
export function addLazyBackground(url, mbUrl) {
    if (mbUrl && window.innerWidth < 768) return mbUrl
    else return url
}
export function wrap(str) {
    return str.split('\n').join('</br>')
}

export function dollarFormatter(dollar) {
    return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
    }).format(dollar * 1)
}
