import { createRouter, createWebHistory } from 'vue-router'
import OfficialWebsite from '../views/OfficialWebsite.vue'
import { officialWebsiteRoutes } from './officialWebsiteRoutes'
import { donateProcessRoutes } from './donateProcessRoutes'
import { memberSystemRoutes } from './memberSystemRoutes'
import { adminSystemRoutes } from './adminSystemRoutes'

const routes = [
    {
        path: '/',
        name: 'OfficialWebsite',
        component: OfficialWebsite,
        children: officialWebsiteRoutes,
    },
    {
        path: '/donate-process',
        name: 'DonateProcess',
        component: () => import('../views/DonateProcess.vue'),
        children: donateProcessRoutes,
    },
    {
        path: '/member',
        name: 'MemberSystem',
        component: () => import('../views/MemberSystem.vue'),
        children: memberSystemRoutes,
    },
    {
        path: '/admin',
        name: 'AdminSystem',
        component: () => import('../views/AdminSystem.vue'),
        children: adminSystemRoutes,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.afterEach((to) => {
    window.scroll({
        top: 0,
        left: 0,
    })
    if (to.meta.title) {
        document.title = to.meta.title + '｜大自然界'
    } else {
        document.title = '大自然界'
    }
})

export default router
