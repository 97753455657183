import Home from '../views/OfficialWebsite/0-Home.vue'

export const officialWebsiteRoutes = [
    {
        path: '',
        name: 'Home',
        component: Home,
    },
    {
        path: 'join',
        name: 'Join',
        component: () => import('../views/OfficialWebsite/0-1-Join.vue'),
        meta: { title: 'お問い合わせ' },
    },
    {
        path: 'news/:newsId',
        name: 'News',
        component: () => import('../views/OfficialWebsite/0-2-News.vue'),
        meta: { title: 'お知らせ' },
    },
    {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () =>
            import('../views/OfficialWebsite/0-3-PrivacyPolicy.vue'),
        meta: { title: '個人情報保護方針' },
    },
    {
        path: 'site-policy',
        name: 'SitePolicy',
        component: () => import('../views/OfficialWebsite/0-4-SitePolicy.vue'),
        meta: { title: 'ご利用規約' },
    },
    {
        path: 'basic-info',
        name: 'BasicInfo',
        component: () => import('../views/OfficialWebsite/0-5-BasicInfo.vue'),
        meta: { title: '基本情報' },
    },
    {
        path: 'commercial-policy',
        name: 'CommercialPolicy',
        component: () =>
            import('../views/OfficialWebsite/0-6-CommercialPolicy.vue'),
        meta: { title: '特定商取引法に基づく表記' },
    },
    {
        path: 'book',
        name: 'Book',
        component: () => import('../views/OfficialWebsite/0-7-Book.vue'),
        meta: { title: '書籍のご案内' },
    },
    {
        path: 'book/:index',
        name: 'BookDetails',
        component: () =>
            import('../views/OfficialWebsite/0-7-1-BookDetails.vue'),
        meta: { title: '書籍の詳細' },
    },
    {
        path: 'intro',
        name: 'Intro',
        component: () => import('../views/OfficialWebsite/1-Intro.vue'),
        meta: { title: 'はじめに' },
    },
    {
        path: 'master',
        name: 'Master',
        component: () => import('../views/OfficialWebsite/2-Master.vue'),
        meta: { title: '『大親神大生命』' },
    },
    {
        path: 'doctrine',
        name: 'Doctrine',
        component: () => import('../views/OfficialWebsite/3-Doctrine.vue'),
        meta: { title: '貴き親理のお授け' },
    },
    {
        path: 'activities',
        name: 'Activities',
        component: () => import('../views/OfficialWebsite/4-Activities.vue'),
        meta: { title: '大自然界の活動' },
    },
    {
        path: 'parents',
        name: 'Parents',
        component: () => import('../views/OfficialWebsite/4-1-Parents.vue'),
        meta: { title: '親祭典の理' },
    },
    {
        path: 'training',
        name: 'Training',
        component: () => import('../views/OfficialWebsite/4-2-Training.vue'),
        meta: { title: '純御修行の理' },
    },
    {
        path: 'thanks',
        name: 'Thanks',
        component: () => import('../views/OfficialWebsite/5-Thanks.vue'),
        meta: { title: '界員の声・親感謝の言葉' },
    },
    {
        path: 'qa',
        name: 'QA',
        component: () => import('../views/OfficialWebsite/6-QA.vue'),
        meta: { title: 'Q&A' },
    },
    {
        path: 'seminar',
        name: 'Seminar',
        component: () => import('../views/OfficialWebsite/7-Seminar.vue'),
        meta: { title: '基本セミナー お申し込み' },
    },
    {
        path: 'donate',
        name: 'Donate',
        component: () => import('../views/OfficialWebsite/8-Donate.vue'),
        meta: { title: 'おつなぎ徳積みの理' },
    },
]
