import MemberSystemLoginIndex from '../views/MemberSystem/0-MemberSystemLoginIndex.vue'
import store from '../store'

export const memberSystemRoutes = [
    {
        path: '',
        name: 'MemberSystemLoginIndex',
        component: MemberSystemLoginIndex,
        children: [
            {
                path: '',
                name: 'MemberSystemLogin',
                component: () =>
                    import('../views/MemberSystem/0-1-MemberSystemLogin.vue'),
                beforeEnter: () => {
                    store.commit('logout')
                },
            },
            {
                path: 'register',
                name: 'MemberSystemRegister',
                component: () =>
                    import(
                        '../views/MemberSystem/0-2-MemberSystemRegister.vue'
                    ),
            },
            {
                path: 'forget-password',
                name: 'ForgetPassword',
                component: () =>
                    import('../views/MemberSystem/0-3-ForgetPassword.vue'),
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: () =>
                    import('../views/MemberSystem/0-4-ResetPassword.vue'),
            },
            {
                path: 'change-password',
                name: 'ChangePassword',
                component: () =>
                    import('../views/MemberSystem/0-5-ChangePassword.vue'),
            },
        ],
    },
    {
        path: 'dashboard',
        name: 'MemberSystemDashboard',
        component: () =>
            import('../views/MemberSystem/1-MemberSystemDashboard.vue'),
        children: [
            {
                path: 'account',
                name: 'MemberSystemAccount',
                component: () =>
                    import('../views/MemberSystem/1-1-MemberSystemAccount.vue'),
                meta: {
                    breadcrumb: [{ title: 'アカウント' }],
                },
            },
            {
                path: 'course',
                name: 'CourseIndex',
                component: () =>
                    import('../views/CourseSystem/0-CourseIndex.vue'),
                children: [
                    {
                        path: '',
                        name: 'CourseList',
                        component: () =>
                            import('../views/CourseSystem/1-CourseList.vue'),
                        meta: {
                            breadcrumb: [{ title: '真理学修' }],
                        },
                    },
                    {
                        path: 'basic/videos',
                        name: 'BasicCourseVideos',
                        component: () =>
                            import(
                                '../views/CourseSystem/2-BasicCourseVideos.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '真理学修',
                                    routeUrl: { name: 'CourseList' },
                                },
                                {
                                    title: '基本セミナー',
                                },
                            ],
                        },
                    },
                    {
                        path: 'advanced',
                        name: 'AdvancedCourseList',
                        component: () =>
                            import(
                                '../views/CourseSystem/3-AdvancedCourseList.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '真理学修',
                                    routeUrl: { name: 'CourseList' },
                                },
                                {
                                    title: '神学科',
                                },
                            ],
                        },
                    },
                    {
                        path: 'advanced/videos/:subCategory',
                        name: 'AdvancedCourseVideos',
                        component: () =>
                            import(
                                '../views/CourseSystem/4-AdvancedCourseVideos.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '真理学修',
                                    routeUrl: { name: 'CourseList' },
                                },
                                {
                                    title: '神学科',
                                    routeUrl: { name: 'AdvancedCourseList' },
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: 'donate',
                name: 'MemberSystemDonateIndex',
                component: () =>
                    import(
                        '../views/MemberSystem/1-2-MemberSystemDonateIndex.vue'
                    ),
                children: [
                    {
                        path: 'stripe',
                        name: 'MemberSystemStripe',
                        component: () =>
                            import(
                                '../views/DonateProcess/1-DonateProcessStripe.vue'
                            ),
                    },
                ],
            },
            {
                path: 'join',
                name: 'CourseForm',
                component: () =>
                    import('../views/MemberSystem/1-3-CourseForm.vue'),
            },
        ],
    },
]
