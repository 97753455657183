import AdminSystemLoginIndex from '../views/AdminSystem/0-AdminSystemLoginIndex.vue'
import store from '../store'

export const adminSystemRoutes = [
    {
        path: '',
        name: 'AdminSystemLoginIndex',
        component: AdminSystemLoginIndex,
        children: [
            {
                path: '',
                name: 'AdminSystemLogin',
                component: () =>
                    import('../views/AdminSystem/0-1-AdminSystemLogin.vue'),
                beforeEnter: () => {
                    store.commit('logout')
                },
            },
            {
                path: 'change-password',
                name: 'AdminChangePassword',
                component: () =>
                    import('../views/AdminSystem/0-2-AdminChangePassword.vue'),
            },
        ],
    },
    {
        path: 'dashboard',
        name: 'AdminSystemDashboard',
        component: () =>
            import('../views/AdminSystem/1-AdminSystemDashboard.vue'),
        children: [
            {
                path: 'member-review',
                name: 'AdminMemberReview',
                component: () =>
                    import('../views/AdminSystem/1-1-AdminMemberReview.vue'),
                meta: {
                    breadcrumb: [{ title: '界員認証' }],
                },
            },
            {
                path: 'member',
                name: 'AdminMemberIndex',
                component: () =>
                    import('../views/AdminSystem/1-2-AdminMemberIndex.vue'),
                children: [
                    {
                        path: '',
                        name: 'MemberOverview',
                        component: () =>
                            import(
                                '../views/AdminSystem/1-2-1-MemberOverview.vue'
                            ),
                        meta: {
                            breadcrumb: [{ title: '界員' }],
                        },
                    },
                    {
                        path: ':userId',
                        name: 'MemberDetails',
                        component: () =>
                            import(
                                '../views/AdminSystem/1-2-2-MemberDetails.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '界員',
                                    routeUrl: { name: 'MemberOverview' },
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: 'course',
                name: 'AdminCourseIndex',
                component: () =>
                    import('../views/CourseSystem/0-CourseIndex.vue'),
                children: [
                    {
                        path: '',
                        name: 'AdminCourseList',
                        component: () =>
                            import('../views/CourseSystem/1-CourseList.vue'),
                        meta: {
                            breadcrumb: [{ title: '真理学修' }],
                        },
                    },
                    {
                        path: 'basic/videos',
                        name: 'AdminBasicCourseVideos',
                        component: () =>
                            import(
                                '../views/CourseSystem/2-BasicCourseVideos.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '真理学修',
                                    routeUrl: { name: 'AdminCourseList' },
                                },
                                {
                                    title: '基本セミナー',
                                },
                            ],
                        },
                    },
                    {
                        path: 'advanced',
                        name: 'AdminAdvancedCourseList',
                        component: () =>
                            import(
                                '../views/CourseSystem/3-AdvancedCourseList.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '真理学修',
                                    routeUrl: { name: 'AdminCourseList' },
                                },
                                {
                                    title: '神学科',
                                },
                            ],
                        },
                    },
                    {
                        path: 'advanced/videos/:subCategory',
                        name: 'AdminAdvancedCourseVideos',
                        component: () =>
                            import(
                                '../views/CourseSystem/4-AdvancedCourseVideos.vue'
                            ),
                        meta: {
                            breadcrumb: [
                                {
                                    title: '真理学修',
                                    routeUrl: { name: 'AdminCourseList' },
                                },
                                {
                                    title: '神学科',
                                    routeUrl: {
                                        name: 'AdminAdvancedCourseList',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            {
                path: 'donate',
                name: 'AdminDonateOverview',
                component: () =>
                    import('../views/AdminSystem/1-3-DonateOverview.vue'),
                meta: {
                    breadcrumb: [{ title: 'おつなぎ徳積み' }],
                },
            },
        ],
    },
]
