<template>
    <div id="Home">
        <h1 class="hide hide-mb">大自然界</h1>
        <section id="home-nav">
            <main>
                <aside>
                    <img
                        id="home-nav-logo"
                        src="@/assets/logo/vertical-logo.png"
                        alt="vertical-logo"
                    />
                    <ul id="home-nav-menu" class="f16 hide-mb">
                        <li
                            v-for="(item, index) in homeNavItems"
                            :key="'home-nav-item-' + index"
                        >
                            <router-link :to="item.routeUrl">
                                <p v-html="wrap(item.title)"></p>
                            </router-link>
                        </li>
                    </ul>
                    <router-link
                        id="home-nav-member"
                        class="f18 serif hide-mb"
                        :to="{ name: 'MemberSystemLogin' }"
                        >界員ページ</router-link
                    >
                    <address id="home-nav-sns" class="hide-mb">
                        <a
                            href="https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q"
                            target="_blank"
                            ><i class="icon-sns-youtube"></i
                        ></a>
                        <a
                            href="https://www.facebook.com/daishizenkai.official/"
                            target="_blank"
                            ><i class="icon-sns-facebook"></i
                        ></a>
                        <a
                            href="https://ameblo.jp/daishizenkai-official/"
                            target="_blank"
                            ><i class="icon-sns-blog"></i
                        ></a>
                    </address>
                </aside>
                <div class="hide mb-img-wrap"></div>
            </main>
            <footer
                id="home-nav-next-section"
                @click="scrollToElement('#home-master')"
                class="hide-mb"
            >
                <h4 class="f14">下へスクロール</h4>
                <i class="icon-next-section f12"></i>
            </footer>
        </section>
        <section id="home-master">
            <h2 class="f29 f18-mb">
                全生物を生かして下さっている<br />
                『生命の大親』
            </h2>
            <p class="f18 f12-mb w80-mb">
                大自然には、私たち全生物に生命を与えて下さり、この世に誕生させ生かして下さっている、<br />
                大いなる生命のお力・お働きがあります。<br />
                私たち全てに生命を与え生かして下さっている、大自然の大いなる生命のお力・お働きを、<br />
                生命の「大親」である「大生命」、『<ruby
                    >大親神大生命<rt>おおおやかみだいせいめい</rt></ruby
                >』とお呼び申し上げます。
            </p>
            <router-link class="f14 f14-mb" :to="{ name: 'Master' }">
                『大親神大生命』とは
                <i class="icon-page-link f12 f12-mb"></i>
            </router-link>
        </section>
        <section
            id="home-doctrine"
            v-lazy:background-image="
                addLazyBackground(
                    require('@/assets/background/background-0-3.jpg'),
                    require('@/assets/background/background-0-3-mb.jpg')
                )
            "
        >
            <h2 class="f29 hide-mb">
                『大親神大生命』
                <span class="f18">は、</span>
            </h2>
            <p class="f18 hide-mb">
                大いなる御心・御意図・御意志をもって、<br />
                大自然の大いなる生命の営みを行っていて下さいます。<br />
                これを親理、大自然の真理法則と言います。
            </p>
            <p class="hide f12-mb">
                『大親神大生命』は、大いなる御心・御意図・御意志をもって、<br />
                大自然の大いなる生命の営みを行っていて下さいます。<br />
                これを親理、大自然の真理法則と言います。
            </p>
            <router-link class="f14 f14-mb" :to="{ name: 'Doctrine' }">
                大自然の真理法則
                <i class="icon-page-link f12 f12-mb"></i>
            </router-link>
        </section>
        <section id="home-intro">
            <p class="f18 hide-mb">
                大自然界とは、<br />
                『大親神大生命』の大いなる御心（＝親理、大自然の真理法則）を学び、<br />
                『大親神大生命』が行っていて下さる全ての営みに感謝し、<br />
                『大親神大生命』の大いなる御心＝親理に沿って、より良く生きて行く会です。<br />
                『大親神大生命』の大いなる御心・生命の愛を実感し感謝して、<br />
                幸せで豊かな人生を一緒に生きてみましょう！
            </p>
            <p class="f12-mb hide">
                大自然界は、<br />
                『大親神大生命』の大いなる御心<br />
                （＝親理、大自然の真理法則）を学び、<br />
                『大親神大生命』が行っていて下さる<br />
                全ての営みに感謝し、<br />
                『大親神大生命』の大いなる御心<br />
                ＝親理に沿って、より良く生きて行く会です。<br />
                『大親神大生命』の大いなる御心・生命の<br />
                愛を実感し感謝して、<br />
                幸せで豊かな人生を一緒に生きてみましょう！
            </p>
            <router-link class="f14 f14-mb" :to="{ name: 'Intro' }">
                詳しく見る（はじめに）
                <i class="icon-page-link f12 f12-mb"></i>
            </router-link>
        </section>
    </div>
</template>
<script>
import getHomeData from '@/data/getHomeData'
import {
    scrollToElement,
    addBackgroundImage,
    addLazyBackground,
    wrap,
} from '@/utils/common.js'
export default {
    name: 'Home',
    setup() {
        const { homeNavItems } = getHomeData()
        return {
            homeNavItems,
            scrollToElement,
            addBackgroundImage,
            addLazyBackground,
            wrap,
        }
    },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1280px) {
    #Home {
        margin-top: -$navHeight;
    }
    #home-nav {
        margin-bottom: $navHeight;
    }
}
@media screen and(min-width:768px) and (max-width: 1279px) {
    #Home {
        margin-top: -$narrowNavHeight;
    }
    #home-nav {
        margin-bottom: $narrowNavHeight;
    }
}
#home-nav {
    position: relative;
    #home-nav-logo {
        display: block;
        margin: 0 auto;
        height: 100px;
    }
    @media screen and (min-width: 768px) {
        height: 100vh;
        overflow: hidden;
        z-index: 1000;
        main {
            background: top center / cover no-repeat;
            background-image: url('~@/assets/background/background-0-1.jpg');
            height: calc(100% - 60px);
        }
        aside {
            float: right;
            width: 350px;
            height: 100%;
            padding: 50px 30px 30px;
            box-sizing: border-box;
            background-color: rgba($white, 0.95);
            border-top: 10px solid $majorPurple;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        #home-nav-menu {
            margin: 40px 0;
            li {
                position: relative;
                border-top: 1px solid rgba($grayB, 0.5);
                padding: 5px 30px;

                a {
                    color: $gray3;
                    transition: 0.3s;
                    &:hover {
                        color: $gray6;
                        p {
                            background: linear-gradient(
                                transparent 70%,
                                $minorPurple 30%
                            );
                            display: inline-block;
                        }
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 15px;
                    width: 5px;
                    height: 0;
                    border: 1px solid $majorPurple;
                }
                &:last-child {
                    border-bottom: 1px solid rgba($grayB, 0.5);
                }
            }
        }
        #home-nav-member {
            @include solidButtonStyle();
        }
        #home-nav-sns {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            a {
                position: relative;
                margin: 0 10px;
                font-size: 28px;
                line-height: 1em;
                color: $gray9;
                transition: 0.3s;
                cursor: pointer;
                &:hover {
                    color: $grayB;
                }
            }
        }
        #home-nav-next-section {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 60px;
            text-align: center;
            background-color: #fff;
            box-sizing: border-box;
            cursor: pointer;
            transition: 0.3s;

            h4 {
                color: $gray0;
            }
            i.icon-next-section {
                color: $gray7;
            }
            &:hover {
                h4 {
                    color: lighten($gray0, 50%);
                }
                i.icon-next-section {
                    color: lighten($gray7, 20%);
                }
            }
        }
    }
    @media screen and(max-width:767px) {
        main {
            display: flex;
            flex-direction: column;
            height: 75vh;
        }
        aside {
            padding: 30px;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            background-color: $white;
        }
        .mb-img-wrap {
            background: top center / cover no-repeat;
            background-image: url('~@/assets/background/background-0-1-mb.jpg');
            flex: 1;
        }
    }
}
#home-master {
    position: relative;
    text-align: center;
    & > * {
        position: relative;
    }
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: center center / cover no-repeat;
        opacity: 0.3;
    }

    h2 {
        position: relative;
        width: max-content;
        margin: 0 auto;
    }
    p {
        margin: 30px auto 40px;
    }
    a {
        display: inline-block;
        @include pageLinkButtonStyle($majorGold);
    }
    @media screen and (min-width: 768px) {
        margin-bottom: 14px;
        padding: 135px 0 60px;
        &::before {
            background-image: url('~@/assets/background/background-0-2.jpg');
        }
    }
    @media screen and(max-width:767px) {
        margin-bottom: 10px;
        padding: 50px 0;
        &::before {
            background-image: url('~@/assets/background/background-0-2-mb.jpg');
        }
    }
}
#home-doctrine {
    text-align: center;
    background: bottom center / cover no-repeat;
    a {
        display: inline-block;
        @include pageLinkButtonStyle($majorPurple);
    }
    @media screen and (min-width: 768px) {
        padding: 90px 0 60px;
        a {
            margin-top: 40px;
        }
    }
    @media screen and(max-width:767px) {
        padding: 40px 0;
        a {
            margin-top: 30px;
        }
    }
}
#home-intro {
    text-align: center;
    p {
        margin-bottom: 10px;
    }
    a {
        display: inline-block;
        margin-top: 20px;
        @include pageLinkButtonStyle($majorPurple);
    }
    @media screen and (min-width: 768px) {
        padding: 60px 0 80px;
    }
    @media screen and(max-width:767px) {
        padding: 50px 0;
    }
}
</style>
