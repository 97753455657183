<template>
    <div id="DonateProcessHome">
        <section id="step1" v-if="stepController === 1">
            <button @click="nextStep">
                <h3 class="f20 f16-mb">一般の方</h3>
                <p class="f12 f12-mb">界員以外の方はこちらからお進み下さい</p>
            </button>
            <router-link @click="setCookie" :to="{ name: 'MemberSystemLogin' }">
                <h3 class="f20 f16-mb">界員の方</h3>
                <p class="f12 f12-mb"></p>
            </router-link>
        </section>
        <!-- 非會員填寫基本資料 -->
        <section
            id="step2"
            class="login-form-style"
            v-if="stepController === 2"
        >
            <ElForm
                :label-position="store.getters.labelPosition"
                label-width="250px"
                ref="userFormRef"
                :model="userFormData"
                :rules="userFormRules"
            >
                <ElFormItem class="f14 f14-mb" label="氏名" prop="name">
                    <ElInput v-model="userFormData.name"></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="電話番号" prop="phone">
                    <ElInput v-model="userFormData.phone"></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス"
                    prop="email"
                >
                    <ElInput v-model="userFormData.email"></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス（確認用）"
                    prop="emailCheck"
                >
                    <ElInput v-model="userFormData.emailCheck"></ElInput>
                </ElFormItem>
                <div class="button-wrap">
                    <button class="f18 f16-mb" @click.prevent="prevStep">
                        前に戻る
                    </button>
                    <button class="f18 f16-mb" @click.prevent="submitUserForm">
                        次へ進む
                    </button>
                </div>
            </ElForm>
        </section>
    </div>
</template>
<script>
export default {
    name: 'DonateProcessHome',
}
</script>
<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getUserFormRules } from '@/utils/formRules'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
const router = useRouter()
const store = useStore()
const stepController = ref(1)

// userForm
const userFormRef = ref(null)
const userFormData = reactive({
    name: '',
    phone: '',
    email: '',
    emailCheck: '',
})
const userFormRules = getUserFormRules(userFormData)
function submitUserForm() {
    userFormRef.value.validate((valid) => {
        if (valid) {
            store.commit('updateUserData', userFormData)
            router.push({ name: 'DonateProcessStripe' })
        } else {
            return false
        }
    })
}

function setCookie() {
    cookies.set('backRoutePath', '/member/dashboard/donate/stripe', 60 * 5)
}

function prevStep() {
    stepController.value -= 1
}
function nextStep() {
    stepController.value += 1
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
#step1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a,
    button {
        flex-direction: column;
        align-items: center;
        vertical-align: center;
        justify-content: center;
        border: $border1;
        margin: 30px 10px;
        border-radius: $borderRadius1;
        background-color: transparent;
        transition: 0.3s;
        width: 300px;
        height: 180px;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
            border: $border2;
        }
    }
    @media screen and (min-width: 768px) {
        a,
        button {
            display: inline-flex;
        }
    }
    @media screen and (max-width: 767px) {
        a,
        button {
            display: flex;
            width: calc(100% - 20px);
            box-sizing: border-box;
        }
    }
}
</style>
