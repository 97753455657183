export const nameRule = [
    {
        required: true,
        message: '漢字でご記入ください',
        trigger: 'blur',
    },
]
export const emailRule = [
    {
        required: true,
        message: 'メールアドレスを入力してください',
        trigger: 'blur',
    },
    {
        validator: (rule, value, callback) => {
            let reg = new RegExp(
                '^[A-Za-z0-9]+((-[A-Za-z0-9]+)|(.[A-Za-z0-9]+))*@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+)*.[A-Za-z]+$'
            )
            if (!reg.test(value)) {
                callback(
                    new Error(
                        'このメールアドレスでは登録できません。恐れ入りますが、他のメールアドレスをご入力ください。'
                    )
                )
            } else {
                callback()
            }
        },
        trigger: 'blur',
    },
]
export const getEmailCheckRule = (formData) => {
    return [
        {
            required: true,
            message: 'メールアドレスの再確認をお願いいたします',
            trigger: 'blur',
        },
        {
            validator: (rule, value, callback) => {
                if (value !== formData.email) {
                    callback(new Error('同じメールアドレスではありません'))
                } else {
                    callback()
                }
            },
            trigger: 'blur',
        },
    ]
}
export const optionRule = [
    {
        required: true,
        message: '選択してください。',
        trigger: 'change',
    },
]
export const phoneRule = [
    {
        required: true,
        message: '電話番号を入力してください。',
        trigger: 'blur',
    },
]
export const amountRule = [
    {
        required: true,
        message: '金額を選択して下さい。',
        trigger: 'change',
    },
    {
        validator: (rule, value, callback) => {
            if (value % 1000) {
                callback(new Error('千円の倍数でお願い致します。'))
            } else {
                callback()
            }
        },
    },
]
export const methodRule = [
    {
        required: true,
        message: '選択してください',
        trigger: 'change',
    },
]
export const passwordRule = [
    {
        required: true,
        message: 'パスワードを入力してください',
        trigger: 'blur',
    },
    {
        min: 5,
        message: '最低でも5桁以上の英数字や記号。',
        trigger: 'blur',
    },
]
export const getPasswordCheckRule = (formData) => {
    return [
        {
            required: true,
            message: '上の欄と同じパスワードを入力してください。',
            trigger: 'blur',
        },
        {
            validator: (rule, value, callback) => {
                if (value !== formData.password) {
                    callback(new Error('パスワードが一致しておりません。'))
                } else {
                    callback()
                }
            },
            trigger: 'blur',
        },
    ]
}
export const birthRule = [
    {
        required: true,
        message: '生年月日を入力してください。',
        trigger: 'blur',
    },
]
export const addressRule = [
    {
        required: true,
        message: '住所を入力してください。',
        trigger: 'blur',
    },
]
export const getFuriganaRule = (formData) => {
    return [
        {
            required: true,
            message: 'ふりがなを入力してください',
            trigger: 'blur',
        },
        {
            validator: (rule, value, callback) => {
                // convert katakana to hiragana.
                let str = value
                formData.furigana = str.replace(
                    /[\u30A1-\u30FF]/g,
                    function (match) {
                        let chr = match.charCodeAt(0) - 0x60
                        return String.fromCharCode(chr)
                    }
                )
                // invalid except for hiragana
                let reg = new RegExp('^[\u3040-\u309F]+$')
                if (!reg.test(formData.furigana)) {
                    callback(new Error('ひらがなで入力してください'))
                } else {
                    callback()
                }
            },
            trigger: 'blur',
        },
    ]
}

export const getSeminarFormRules = (formData) => {
    return {
        name: nameRule,
        email: emailRule,
        emailCheck: getEmailCheckRule(formData),
    }
}
export const getJoinFormRules = (formData) => {
    return {
        name: nameRule,
        email: emailRule,
        emailCheck: getEmailCheckRule(formData),
        option: optionRule,
    }
}
export const getUserFormRules = (formData) => {
    return {
        phone: phoneRule,
        email: emailRule,
        emailCheck: getEmailCheckRule(formData),
        name: nameRule,
    }
}
export const getAmountFormRules = () => {
    return {
        amount: amountRule,
        method: methodRule,
    }
}
export const getLoginFormRules = () => {
    return {
        email: emailRule,
        password: passwordRule,
    }
}
export const getRegisterFormRules = (formData) => {
    return {
        email: emailRule,
        emailCheck: getEmailCheckRule(formData),
        password: passwordRule,
        passwordCheck: getPasswordCheckRule(formData),
    }
}
export const getUserDetailsFormRules = (formData) => {
    return {
        name: nameRule,
        birth: birthRule,
        phone: phoneRule,
        address: addressRule,
        furigana: getFuriganaRule(formData),
    }
}
export const getCourseFormRules = (formData) => {
    return {
        name: nameRule,
        email: emailRule,
        emailCheck: getEmailCheckRule(formData),
        select1: optionRule,
        select2: optionRule,
    }
}
