import DonateProcessHome from '../views/DonateProcess/0-DonateProcessHome.vue'

export const donateProcessRoutes = [
    {
        path: '',
        name: 'DonateProcessHome',
        component: DonateProcessHome,
    },
    {
        path: 'stripe',
        name: 'DonateProcessStripe',
        component: () =>
            import('../views/DonateProcess/1-DonateProcessStripe.vue'),
    },
    {
        path: 'success',
        name: 'DonateProcessSuccess',
        component: () =>
            import('../views/DonateProcess/2-DonateProcessSuccess.vue'),
    },
    {
        path: 'success/:clientSecret',
        name: 'DonateProcessSuccessClientSecret',
        component: () =>
            import('../views/DonateProcess/2-DonateProcessSuccess.vue'),
    },
]
