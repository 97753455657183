import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import _ from 'lodash'

const moduleIdentity = {
    state: {
        token: null,
        serverRole: 'guest',
    },
    mutations: {
        updateUserData(state, userData) {
            Object.keys(userData).forEach((key) => {
                state[key] = userData[key]
            })
        },
    },
    actions: {},
    getters: {
        getToken(state) {
            return state.token
        },
        getServerRole(state) {
            return state.serverRole
        },
        getUserLevel(state) {
            return state.level
        },
        getUserData(state) {
            return {
                userId: state.userId,
                name: state.name,
                phone: state.phone,
                email: state.email,
            }
        },
        getUserState(state) {
            return state
        },
    },
}
const moduleAdmin = {
    state: {
        memberReviewCount: 0,
    },
    mutations: {
        updateMemberReviewCount(state, count) {
            state.memberReviewCount = count
        },
    },
    getters: {
        getMemberReviewCount(state) {
            return state.memberReviewCount
        },
    },
}
const moduleDonate = {
    state: {
        donateStatus: {
            基本セミナー: false,
            '神学科・第一段階': false,
            '神学科・第二段階': false,
            '神学科・第三段階': false,
            '神学科・第四段階': false,
            '神学科・第五段階': false,
        },
        donateRecord: [],
    },
    mutations: {
        updateDonateStatus(state, donateStatus) {
            Object.keys(donateStatus).forEach((key) => {
                state.donateStatus[key] = donateStatus[key]
            })
        },
        updateDonateRecord(state, donateRecord) {
            state.donateRecord = _.cloneDeep(donateRecord)
        },
        updateTheologySubcategory(state, subCategory) {
            state.theologySubcategory = _.cloneDeep(subCategory)
        },
    },
    getters: {
        getDonateStatus(state) {
            return state.donateStatus
        },
        getDonateRecord(state) {
            return state.donateRecord
        },
    },
}
const moduleCourse = {
    state: {
        courseStatus: {},
        theologyStatus: [
            {
                subCategory: '神学科・第一段階',
            },
            {
                subCategory: '神学科・第二段階',
            },
            {
                subCategory: '神学科・第三段階',
            },
            {
                subCategory: '神学科・第四段階',
            },
            {
                subCategory: '神学科・第五段階',
            },
        ],
    },
    mutations: {
        updateCourseStatus(state, courseStatus) {
            for (let item of courseStatus) {
                if (!state.courseStatus[item.mainCategory]) {
                    state.courseStatus[item.mainCategory] = {}
                }
                if (!state.courseStatus[item.mainCategory][item.subCategory]) {
                    state.courseStatus[item.mainCategory][item.subCategory] = []
                }
                state.courseStatus[item.mainCategory][item.subCategory].push(
                    item
                )
            }
            state.theologyStatus.forEach((item) => {
                let arr = state.courseStatus.神学科[item.subCategory]
                item.status = true
                for (let subitem of arr) {
                    item.status = item.status && subitem.isWatched
                }
            })
            console.log(state.theologyStatus)
        },
        watchCourse(state, item) {
            console.log(item.number)
            state.courseStatus[item.mainCategory][item.subCategory][
                item.number -
                    state.courseStatus[item.mainCategory][item.subCategory][0]
                        .number
            ].isWatched = true

            if (item.mainCategory === '神学科') {
                state.theologyStatus.forEach((item) => {
                    let arr = state.courseStatus.神学科[item.subCategory]
                    item.status = true
                    for (let subitem of arr) {
                        item.status = item.status && subitem.isWatched
                    }
                })
            }
        },
    },
    getters: {
        getTheologyStatus: (state) => (subCategory) => {
            for (let item of state.theologyStatus) {
                if (item.subCategory === subCategory) return item.status
            }
        },
        getBasicVideos: (state) => {
            return state.courseStatus.基本セミナー.基本セミナー
        },
        getTheologyVideos: (state) => (subCategory) => {
            return state.courseStatus.神学科[subCategory]
        },
        getTheologySubcategory: (state) => {
            return state.theologyStatus
        },
        getNextSubcategory: (state) => (subCategory) => {
            for (let i = 0; i < state.theologyStatus.length - 1; i++) {
                if (state.theologyStatus[i].subCategory === subCategory)
                    return state.theologyStatus[i + 1].subCategory
            }
        },
    },
}
const moduleDevice = {
    state: {
        isPC: true,
        labelPosition: 'right',
    },
    mutations: {
        updateDevice(state) {
            if (window.innerWidth < 768) {
                state.isPC = false
                state.labelPosition = 'top'
            } else {
                state.isPC = true
                state.labelPosition = 'right'
            }
        },
    },
    getters: {
        isPC(state) {
            return state.isPC
        },
        labelPosition(state) {
            return state.labelPosition
        },
    },
}
const moduleDonateDialog = {
    state: {
        donateDialogStatus: {
            基本セミナー: false,
            '神学科・第一段階': false,
            '神学科・第二段階': false,
            '神学科・第三段階': false,
            '神学科・第四段階': false,
            '神学科・第五段階': false,
        },
    },
    mutations: {
        updateDonateDialogStatus(state, key) {
            state.donateDialogStatus[key] = true
        },
    },
    getters: {
        getDonateDialogStatus(state) {
            return state.donateDialogStatus
        },
    },
}

const PERSIST_PATH = [
    'moduleIdentity',
    'moduleDevice',
    'moduleDonate',
    'moduleAdmin',
    'moduleCourse',
    'moduleDonateDialog',
]
export default createStore({
    state: {},
    mutations: {
        logout(state) {
            Object.keys(state.moduleIdentity).forEach((key) => {
                if (key === 'token') state.moduleIdentity[key] = null
                else if (key === 'serverRole')
                    state.moduleIdentity[key] = 'guest'
                else delete state.moduleIdentity[key]
            })
            Object.keys(state.moduleDonate).forEach((key) => {
                if (key === 'donateStatus') {
                    Object.keys(state.moduleDonate[key]).forEach((subKey) => {
                        state.moduleDonate[key][subKey] = false
                    })
                } else state.moduleDonate[key] = []
            })
            Object.keys(state.moduleCourse).forEach((key) => {
                if (key === 'courseStatus') {
                    state.moduleCourse[key] = {}
                } else if (key === 'theologyStatus') {
                    state.moduleCourse[key] = [
                        {
                            subCategory: '神学科・第一段階',
                        },
                        {
                            subCategory: '神学科・第二段階',
                        },
                        {
                            subCategory: '神学科・第三段階',
                        },
                        {
                            subCategory: '神学科・第四段階',
                        },
                        {
                            subCategory: '神学科・第五段階',
                        },
                    ]
                } else state.moduleCourse[key] = ''
            })
            Object.keys(state.moduleAdmin).forEach((key) => {
                delete state.moduleAdmin[key]
            })
        },
    },
    actions: {},
    modules: {
        moduleIdentity,
        moduleDevice,
        moduleDonate,
        moduleCourse,
        moduleAdmin,
        moduleDonateDialog,
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            paths: PERSIST_PATH,
        }),
    ],
})
