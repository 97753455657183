<template>
    <div id="OfficialWebsite">
        <NavBar></NavBar>
        <router-view></router-view>
        <FooterSection></FooterSection>
        <div id="to-top" @click="scrollToTop()">
            <i class="icon-to-top"></i>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OfficialWebsite',
}
</script>
<script setup>
import { defineAsyncComponent } from 'vue'
const NavBar = defineAsyncComponent(() => import('@/components/NavBar.vue'))
const FooterSection = defineAsyncComponent(() =>
    import('@/components/FooterSection.vue')
)

function scrollToTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    })
}
</script>
<style lang="scss" scoped></style>
