<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {}
</script>
<script setup>
import { useStore } from 'vuex'
const store = useStore()

let timer
window.onresize = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
        store.commit('updateDevice')
    }, 300)
}
</script>
<style lang="scss">
#app {
    position: relative;
    font-family: 'vdl-v7mincho', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
}

@media screen and (min-width: 768px) {
    #app {
        min-width: 960px;
    }
}

#to-top {
    position: fixed;
    right: 30px;
    bottom: 25px;
    z-index: 300;
    width: 50px;
    height: 50px;

    color: $majorPurple;
    text-align: center;
    line-height: 47px;

    background-color: white;
    border-radius: 50%;
    box-shadow: $shadow2;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: lighten($majorPurple, 10%);
    }
}
</style>
