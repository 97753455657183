<template>
    <div id="MemberSystemLoginIndex" class="serif border-system-style">
        <router-link :to="{ name: 'Home' }"
            ><img :src="require('@/assets/logo/vertical-logo.png')"
        /></router-link>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'MemberSystemLoginIndex',
}
</script>
<script setup></script>
<style lang="scss" scoped>
.border-system-style img {
    margin-bottom: 0px;
}
</style>
