export default function () {
    const homeNavItems = [
        {
            title: 'はじめに',
            routeUrl: { name: 'Intro' },
        },
        {
            title: '生命の大親『大親神大生命』',
            routeUrl: { name: 'Master' },
        },
        {
            title: '貴き親理のお授け\n大自然の真理法則',
            routeUrl: {
                name: 'Doctrine',
            },
        },
        {
            title: '大自然界の活動',
            routeUrl: { name: 'Activities' },
        },
        {
            title: 'おつなぎ徳積みの理',
            routeUrl: { name: 'Donate' },
        },
        {
            title: '界員の声・親感謝の言葉',
            routeUrl: { name: 'Thanks' },
        },
        {
            title: 'Q&A',
            routeUrl: { name: 'QA' },
        },
        {
            title: '基本セミナー・お申し込み',
            routeUrl: { name: 'Seminar' },
        },
        {
            title: 'お問い合わせ',
            routeUrl: { name: 'Join' },
        },
    ]

    return { homeNavItems }
}
