import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from '@jambonn/vue-lazyload'

import './style/reset.css'
import './font/iconmoon/style.css'

import smoothscroll from 'smoothscroll-polyfill'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import locale from '@/utils/locale.js'

import VueGTag from 'vue-gtag'

createApp(App)
    .use(store)
    .use(router)
    .use(VueLazyload)
    .use(ElementPlus, {
        locale,
    })
    .use(
        VueGTag,
        {
            config: {
                id: '{ G-RKSSJW05BY }',
            },
        },
        router
    )
    .mount('#app')
smoothscroll.polyfill()
